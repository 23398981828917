<template>
  <div class="mPartner">
    <div class="headImgBox">
      <button class="justApply headImgBtn" @click.stop="toApply">立即申请</button>
    </div>
    <div class="content">
      <p class="title">成为河伙人，您将拥有...</p>
      <div class="rollCardBox" @scroll="paperScroll1">
        <div class="allCardBox" id="allCard1">
          <div class="cardItem" ref="card11">
            <div class="card1Name">
              <p>200+</p>
            </div>
            <div class="card1Des">
              <p>多名宝妈收入破万</p>
            </div>
          </div>
          <div class="cardItem" ref="card12">
            <div class="card1Name font48">
              <p>考试培训</p>
              <p>名字辅导</p>
            </div>
            <div class="card1Des">
              <p>河伙人专属福利</p>
            </div>
          </div>
          <div class="cardItem" ref="card13">
            <div class="card1Name font48">
              <p>更多福利</p>
              <p>正在建设中</p>
            </div>
            <div class="card1Des">
              <p>敬请期待</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dotBox">
        <span class="dot" :class="newDot1 == 1 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot1 == 2 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot1 == 3 ? 'newDot' : ''"></span>
      </div>
      <p class="title">我们希望您...</p>
      <div class="rollCardBox" @scroll="paperScroll2">
        <div class="allCardBox" id="allCard2">
          <div class="cardItem" ref="card21">
            <img src="https://hexiaoxiang-xcx.oss-cn-hangzhou.aliyuncs.com/web_static/partner4.png" class="card2Img">
            <div class="card2Des">
              <p>认可河小象理念</p>
              <p>并愿意推广</p>
            </div>
          </div>
          <div class="cardItem" ref="card22">
            <img src="https://hexiaoxiang-xcx.oss-cn-hangzhou.aliyuncs.com/web_static/partner5.png" class="card2Img">
            <div class="card2Des">
              <p>有丰富的宝妈好友</p>
            </div>
          </div>
          <div class="cardItem" ref="card23">
            <img src="https://hexiaoxiang-xcx.oss-cn-hangzhou.aliyuncs.com/web_static/partner6.png" class="card2Img">
            <div class="card2Des">
              <p>富有想法</p>
              <p>创造和挑战精神</p>
            </div>
          </div>
        </div>
      </div>
      <div class="dotBox">
        <span class="dot" :class="newDot2 == 1 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot2 == 2 ? 'newDot' : ''"></span>
        <span class="dot" :class="newDot2 == 3 ? 'newDot' : ''"></span>
      </div>
      <button class="justApply" @click.stop="toApply">立即申请</button>
    </div>
    <m-footer class="footer"></m-footer>
  </div>
</template>

<script>
import mFooter from '../components/mFooter';
export default {
  components:{
    mFooter
  },
  name: 'mPartner',
  data () {
    return {
      newDot1: 1,
      newDot2: 1,
    }
  },
  created() {
    this.setPageSize();
  },
  methods: {
    setPageSize() {
      !function(e,t){function n(){t.body?t.body.style.fontSize=12*o+"px":t.addEventListener("DOMContentLoaded",n)}function d(){var e=i.clientWidth/10;i.style.fontSize=e+"px"}var i=t.documentElement,o=e.devicePixelRatio||1;if(n(),d(),e.addEventListener("resize",d),e.addEventListener("pageshow",function(e){e.persisted&&d()}),o>=2){var a=t.createElement("body"),s=t.createElement("div");s.style.border=".5px solid transparent",a.appendChild(s),i.appendChild(a),1===s.offsetHeight&&i.classList.add("hairlines"),i.removeChild(a)}}(window,document);
    },
    toApply() {
      window.open('https://www.wjx.cn/jq/25914315.aspx');
    },
    paperScroll1() {
      let width = this.$refs.card11.getBoundingClientRect().width;
      let left1 = this.$refs.card11.getBoundingClientRect().left;
      let left2 = this.$refs.card12.getBoundingClientRect().left;
      let left3 = this.$refs.card13.getBoundingClientRect().left;
      let newWidth = width/5*5.5;

      if (0<left1 && left1<newWidth) {
        this.newDot1 = 1;
      } else if (0<left2 && left2<newWidth) {
        this.newDot1 = 2;
      } else if (0<left3 && left3<newWidth) {
        this.newDot1 = 3;
      }
    },
    paperScroll2() {
      let width = this.$refs.card21.getBoundingClientRect().width;
      let left1 = this.$refs.card21.getBoundingClientRect().left;
      let left2 = this.$refs.card22.getBoundingClientRect().left;
      let left3 = this.$refs.card23.getBoundingClientRect().left;
      let newWidth = width/5*5.6;
      if (0<left1 && left1<newWidth) {
        this.newDot2 = 1;
      } else if (0<left2 && left2<newWidth) {
        this.newDot2 = 2;
      } else if (0<left3 && left3<newWidth) {
        this.newDot2 = 3;
      }
    },
    // 自动换
    autoScroll() {
      let that = this;
      setInterval(() => {
        let width = document.body.scrollWidth/2;
        let needLen = width/5*6.1;
        if (that.newDot1 === 1) {
          document.getElementById("allCard1").setAttribute('style', `transform: translateX(-${needLen}px);transition: transform .5s;`);
          that.newDot1 = 2
        } else if (that.newDot1 === 2) {
          document.getElementById("allCard1").setAttribute('style', `transform: translateX(-${needLen*2}px);transition: transform .5s;`);
          that.newDot1 = 3
        } else if (that.newDot1 === 3) {
          document.getElementById("allCard1").setAttribute('style', `transform: translateX(0px);transition: transform .5s;`);
          that.newDot1 = 1;
        }
      }, 8000);

      setInterval(() => {
        let width = document.body.scrollWidth/2;
        let needLen = width/5*6.1;
        if (that.newDot2 === 1) {
          document.getElementById("allCard2").setAttribute('style', `transform: translateX(-${needLen}px);transition: transform .5s;`);
          that.newDot2 = 2
        } else if (that.newDot2 === 2) {
          document.getElementById("allCard2").setAttribute('style', `transform: translateX(-${needLen*2}px);transition: transform .5s;`);
          that.newDot2 = 3
        } else if (that.newDot2 === 3) {
          document.getElementById("allCard2").setAttribute('style', `transform: translateX(0px);transition: transform .5s;`);
          that.newDot2 = 1;
        }
      }, 8000);

      // setInterval(() => {
      //   let width = document.body.scrollWidth/2;
      //   let needLen = width/5*6.1;
      //   if (that.newDot2 === 1) {
      //     // let i = 1;
      //     // let a1 = setInterval(function() {
      //     //   i++;
      //     //   document.getElementById("allCard2").scrollLeft = needLen * i;
      //     //   if (i == 60) {
      //     //     clearInterval(a1);
      //     //   }
      //     // }, 5);
      //     document.getElementById("allCard2").scrollLeft = needLen;
      //   } else if (that.newDot2 === 2) {
      //     // let i = 1;
      //     // let b1 = setInterval(function() {
      //     //   i++
      //     //   document.getElementById("allCard2").scrollLeft = needLen*60 + needLen * i;
      //     //   if (i == 60) {
      //     //     clearInterval(b1);
      //     //   }
      //     // }, 5);
      //     document.getElementById("allCard2").scrollLeft = needLen*2;
      //   } else if (that.newDot2 === 3) {
      //     // let i = 120;
      //     // let c1 = setInterval(function() {
      //     //   i--;
      //     //   document.getElementById("allCard2").scrollLeft = needLen * i;
      //     //   if (i == 0) {
      //     //     clearInterval(c1);
      //     //   }
      //     // }, 5);
      //     document.getElementById("allCard2").scrollLeft = 0;
      //   }
      // }, 8000);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mPartner {
  margin-top: 1.52rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: auto;
  color: #fff;
}
.headImgBox {
  position: relative;
  width: 100vw;
  height: 5.53rem;
  overflow: hidden;
  background-color: #FB5469;
  background-image: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/partner.png');
  background-size: 100vw 5.53rem;
}
.justApply {
  width: 8.67rem;
  height: 1.17rem;
  border-radius: 1rem;
  line-height: 1.17rem;
  font-size: 0.43rem;
  color: #fff;
  border: none;
  outline: none;
  background-color: #13B4FE;
}
.headImgBtn {
  position: absolute;
  bottom: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
}
.justApply:active {
  background:rgba(19,180,254,0.6);
}
.content {
  padding: 0.85rem 0;
  width: 100vw;
  height: auto;
  background-color: #fff;
}
.title {
  margin-bottom: 1.12rem;
  width: 100vw;
  height: 0.67rem;
  line-height: 0.67rem;
  font-size: 0.67rem;
  color: #515558;
}
.rollCardBox {
  width: 100vw;
  height: 5.5rem;
  overflow: hidden;
  overflow-x: auto;
}
.rollCardBox::-webkit-scrollbar {
  display: none;
}
.allCardBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 17.2rem;
  height: 5.5rem;
  padding: 0 2.5rem;
}
.cardItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 5rem;
  height: 5rem;
  box-shadow:0px 2px 10px 0px rgba(0,0,0,0.26);
}
.dotBox {
  margin-bottom: 0.89rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 0.6rem;
}
.dot {
  margin: 0.3rem 0.3rem 0;
  width: 0.12rem;
  height: 0.12rem;
  border: 0.5px solid #ccc;
  border-radius: 0.1rem;
  background-color: #fff;
}
.newDot {
  background-color: #13B4FE;
}
.card1Name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.8rem;
  line-height: 0.9rem;
  font-size: 0.9rem;
  color: #71C5FF;
  font-weight: bold;
}
.font48 {
  font-size: 0.64rem;
}
.card1Des {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 1.2rem;
  line-height: 0.8rem;
  font-size: 0.373rem;
  color: #515558;
}
.card2Img {
  width: 100%;
  height: 3.15rem;
  background-color: #ccc;
}
.card2Des {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1.85rem;
  line-height: 0.64rem;
  font-size: 0.37rem;
  color: #515558;
}
</style>
